import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import moment from 'moment'
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'
import Row from '../components/row'
import Column from '../components/column'
import StoryTile from '../components/story-tile'
import PortfolioTile from '../components/portfolio-tile'
import Map from '../components/map'

import {
    EnergyIcon,
    PlayIcon,
    OnSwitchIcon,
    CheckIcon,
    MonitorIcon,
    HandshakeIcon
} from '../components/icons'

import headerVideoWebM from '../videos/Header_Video.webm'
import headerVideoMp4 from '../videos/Header_Video.mp4'

const IndexPage = ({ location }) => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "stories"}}, sort: {fields: name}) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              width: 480
              height: 480
              transformOptions: {
                cropFocus: ATTENTION
              }
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      allContentfulAktuelles(
        filter: {
          company: { elemMatch: { name: { eq: "GE-T" } } }
          node_locale: { eq: "de" }
        }
        sort: {fields: publishedAt, order: DESC}
        limit: 2
      ) {
        nodes {
          title
          slug
          publishedAt
          excerpt {
            excerpt
          }
        }
      }
    }
    `)

    const PageHeader = <Header className="home-header" title="GE­-T fascinated." backgroundVideoWebM={headerVideoWebM} backgroundVideoMp4={headerVideoMp4} />

    return (
        <Layout
            translatedPageLinks={[{ code: 'de', link: '/de/' }, { code: 'en', link: '/en/' }]}
            pageLang="de"
            pageTitle="Start"
            pageDescription="Die GE-T GmbH ist ein innovativer Entwicklungsdienstleister in der Automotive- und Smarthome-Branche in Wolfsburg."
            pageHeader={PageHeader}
            location={location}
            lightNavbar
        >
            <section id="about">
                <Container>
                    <p style={{ fontSize: '25px', fontWeight: 600 }}>Mensch. Mobilität. Wohnen. In Zukunft sind diese drei Komponenten nicht mehr getrennt voneinander zu denken. Sie interagieren miteinander: als ein Ökosystem. </p>
                    <p>
                        Als innovative Technologieschmiede entwickelt GE-T intelligente, erlebbare Bedienelemente.
                        Wir verfolgen die Vision, dem Auto und dem Haus der Zukunft das Fühlen, Hören und Sehen beizubringen.
                        Unsere Touch-Technologie kommt schon heute sowohl im Fahrzeuginnenraum als auch im Smarthome zum Einsatz.
                    </p>
                    <p>
                        Spätestens mit dem bi-direktionalen Laden werden Auto und Haus eine Symbiose eingehen.
                        Wir denken voraus, denn wir wissen: Unsere Welt ist auf Beschleunigungskurs.
                        Die Fahrzeugentwicklung wird schneller, die Markteinführungszyklen kürzer.
                        Deshalb haben wir uns angeschnallt und fokussieren uns auf schnelle, individuelle und intelligente Lösungen.
                    </p>
                </Container>
            </section>
            <section id="stories" className="blue-section">
                <Container>
                    <h2>Stories</h2>
                    <div className="stories">
                        <Row>
                            <Column columnWidth={4}>
                                <StoryTile to="/de/story/multifunktions-touchlenkrad/" image={data.allFile.nodes[0]} title="Das Multifunktions-Touchlenkrad" animationDuration="200" />
                            </Column>
                            <Column columnWidth={4}>
                                <StoryTile to="/de/story/tuerbedienmodul/" image={data.allFile.nodes[2]} title="Das Türbedienmodul" animationDuration="400" />
                            </Column>
                            <Column columnWidth={4}>
                                <StoryTile to="/de/story/wallbox/" image={data.allFile.nodes[3]} title="Die Wallbox" animationDuration="600" />
                            </Column>
                            <Column columnWidth={4}>
                                <StoryTile to="/de/story/prueffinger/" image={data.allFile.nodes[1]} title="Der Prüffinger" animationDuration="800" />
                            </Column>
                        </Row>
                    </div>
                </Container>
            </section>
            <section id="portfolio">
                <Container>
                    <h2>Portfolio</h2>
                    <Row>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Innovation-Hub" icon={<EnergyIcon />}>
                                Innovative Ideen begeistern uns. 
                                Wir unterstützen Sie bei der Entwicklung und Umsetzung und bieten eine vollständige Betreuung des gesamten Prozesses – vom ersten Strich bis zum fertigen Produkt. 
                                Das Innovation-Hub bildet dafür die gemeinsame Plattform.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Interior Gestaltung" icon={<PlayIcon />} animationOffset="250">
                                Wir widmen uns leidenschaftlich der Gestaltung eines komfortablen, ästhetischen wie auch funktionalen Fahrzeuginnenraums. 
                                Von entsprechender Hard- und Software über Beleuchtungs- und Akustikkonzepte bis hin zu haptischem Feedback und flexiblen Displays – wir entwickeln die passenden Komponenten. 
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Competence-Center-Touch" icon={<OnSwitchIcon />}>
                                Unser Competence-Center-Touch widmet sich vollumfänglich dem Thema Touch-Technologie. 
                                Kapazitive Bedienelemente wie auch Displays werden anhand einer Vielzahl individueller Testverfahren einer detaillierten, komponenten&shy;spezifischen Prüfung unterzogen. 
                                Zusätzlich bauen wir Prüfstände auf, definieren Anforderungen an Bauteile, bewerten und selektieren Testmethoden. 
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Testhaus" icon={<CheckIcon />} animationOffset="250">
                                Kann eine Wallbox extremen Minusgraden standhalten? 
                                Welchen Einfluss haben Staubpartikel auf die Funktionalität eines Lenkrades? 
                                In unserem Testhaus verfügen wir über umfangreiche Laborkapazitäten, um diverse Komponenten auf ihre Widerstands&shy;fähigkeit zu prüfen: 
                                ein Batterie- und ein Akustiklabor sowie Prüfboxen, die verschiedene Umwelteinflüsse simulieren.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Software- und Appentwicklung" icon={<MonitorIcon />}>
                                Wir entwickeln Hard- und Software für embedded Systems sowie Apps zur Steuerung und Auswertung verschiedenster Systeme. 
                                Auch die Entwicklung künstlicher Intelligenz (KI) ist Teil unseres Portfolios.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Resident-Engineering" icon={<HandshakeIcon />} animationOffset="250">
                                Unsere Resident Engineers unterstützen weltweit aufgestellte Lieferanten dabei, 
                                einen Einstieg in die komplexen Konzernstrukturen von Volkswagen zu finden, 
                                die eigenen Prozessabläufe zu optimieren und Kommunikationswege zu vereinfachen. 
                                Sowohl mit dem Volkswagen Konzern als auch mit der ES-Tec GmbH sind sie hervorragend vernetzt.
                            </PortfolioTile>
                        </Column>
                    </Row>
                </Container>
            </section>
            <section id="news" className="blue-section">
                <Container>
                    <h2>Aktuelles</h2>
                    <Row>
                        {data.allContentfulAktuelles.nodes.map((article) => {
                            const publishedAt = moment(article.publishedAt).format(
                                'DD.MM.YYYY'
                            )

                            return (
                                <Column columnWidth={2} key={article.slug}>
                                    <Link
                                        className="article"
                                        to={'/de/aktuelles/' + article.slug + '/'}
                                    >
                                        <div className="date">
                                            <h5>{publishedAt}</h5>
                                        </div>

                                        <div className="title">
                                            <h3>{article.title}</h3>
                                        </div>

                                        <div className="excerpt">
                                            <p>{article.excerpt.excerpt}&hellip;</p>
                                        </div>

                                        <div className="read-more">
                                            <span className="button button-white">Weiterlesen</span>
                                        </div>
                                    </Link>
                                </Column>
                            )
                        })}
                    </Row>
                </Container>
            </section>
            <section id="contact">
                <Container>
                    <h2>Kontakt</h2>
                    <Row>
                        <Column columnWidth={3}>
                            <h3>GE-T GmbH</h3>
                            <p>
                                Marie-Curie-Str. 6<br />
                                85055 Ingolstadt
                            </p>
                            <p>
                                E-Mail: info<span style={{display: 'none'}}>sp0m</span>@ge-t.de<br />
                                Telefon: +49 (0) 841 14907699
                            </p>
                        </Column>
                        <Column columnWidth={3}>
                            <h3>Betriebsstätten in Wolfsburg</h3>
                            <p>
                                Brandgehaege 16<br />
                                38444 Wolfsburg<br /><br />
                            </p>
                            <p>Telefon: +49 (0) 5308 52112200</p>   
                        </Column>
                        <Column columnWidth={3}>
                            <h3>Betriebsstätten in Berlin</h3>
                            <p>
                                Albert-Einstein-Straße 14<br />
                                (Technologiepark Adlershof)<br />
                                12489 Berlin
                            </p>
                            <p>Telefon: + 49 (0) 172 6735460</p>
                        </Column>
                    </Row>
                </Container>
            </section>
            <Map lat="52.370596331898184" lng="10.726932262089171" zoom="16" addPinAtCenter />
        </Layout >
    )
}

export default IndexPage
