import React, { useRef, useEffect, useContext } from 'react'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker'
import { LanguageContext } from '../utils/translation'

const MAPBOX_TOKEN = "pk.eyJ1IjoibXNjZyIsImEiOiJja29sZmNlcGYwNnhkMm9wbjkwdTFrMnJuIn0.6Ew67t_sZzqywR7W9oA-Hg"
mapboxgl.workerClass = MapboxWorker

const Map = ({ lat, lng, zoom, addPinAtCenter }) => {
    const lang = useContext(LanguageContext)
    const mapContainer = useRef()

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            accessToken: MAPBOX_TOKEN,
            style: 'mapbox://styles/mapbox/light-v10',
            locale: lang === 'de' ? {
                'TouchPanBlocker.Message': 'Verwenden Sie zwei Finger, um die Karte zu bewegen'
            } : null,
            cooperativeGestures: true,
            scrollZoom: false,
            center: [lng, lat],
            zoom: zoom || 10,
            pitch: 50
        })

        if (addPinAtCenter)
            new mapboxgl.Marker({ color: '#00d37f' })
                .setLngLat([lng, lat])
                .addTo(map)

        return () => map.remove()
    })

    return <div className="map" ref={mapContainer} />
}

export default Map