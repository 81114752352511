import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const StoryTile = ({ to, image, title, animationDuration }) => {
    const img = getImage(image)

    return (
        <div className="story" data-aos="fade-up" data-aos-duration={animationDuration}>
            <Link to={to}>
                <GatsbyImage
                    image={img}
                    alt={title}
                />
                <h3>{title}</h3>
            </Link>
        </div>
    )
}

export default StoryTile