import * as React from 'react'
import { Link } from 'gatsby'

const PortfolioTile = ({ title, link, icon, animationOffset, children }) => (
    <div className="portfolio" data-aos="fade-up" data-aos-offset={animationOffset}>
        <h3>{title}</h3>
        <div className="text">
            {children}
        </div>
        <div className="icon">{icon}</div>
        {link && <Link to={link} className="button button-white">Mehr erfahren</Link>}
    </div>
)

export default PortfolioTile